import { memo, useEffect, useRef, useState } from 'react';
import { useScroll, motion, useMotionValueEvent } from "framer-motion";
import android from "../../../assets/images/playstore.png"
import apple from "../../../assets/images/apple.svg"

const PinterestGrid = ({ items }) => {
   //bg-[url('./assets/images/ellipse.png')]

   return (
      <div class="columns-4 overflow-hidden z-10 space-y-3 ">
         {items.map((item, index) => (
            <div className="rounded-2xl bg-black h-72 w-64 bg-no-repeat bg-cover overflow-hidden"
               style={{ backgroundImage: `url(${item.image})` }}>
            </div>
         ))
         }
      </div >
   );
};



function HeroUI({ products }) {

   const getOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator?.platform || '';

      if (/Mac/i.test(platform)) {
         return 'MacOS';
      }
      if (/Win/i.test(platform)) {
         return 'Windows';
      }
      if (/Linux/i.test(platform)) {
         return 'Linux';
      }
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
         return 'iOS';
      }
      if (/Android/i.test(userAgent)) {
         return 'Android';
      }
      return 'Unknown';
   };

   const [os, setOS] = useState('');

   const items = [
      { title: 'Item 1', image: require("../../../assets/images/books.png") },
      { title: 'Item 3', image: require("../../../assets/images/bags.png") },
      { title: 'Item 3', image: require("../../../assets/images/shoes.png") },
      { title: 'Item 2', image: require("../../../assets/images/maxim-hopman-Hin-rzhOdWs-unsplash.jpg") },
      { title: 'Item 4', image: require("../../../assets/images/premium_photo-1694141252774-c937d97641da.webp") },
      { title: 'Item 5', image: require("../../../assets/images/photo-1701291927826-c7775869d822.webp") },
      { title: 'Item 6', image: require("../../../assets/images/premium_photo-1673125287084-e90996bad505.webp") },
   ];


   useEffect(() => {
      setOS(getOS());
   }, []);


   useEffect(() => {
      const boxes = document.querySelectorAll('.box');
      boxes.forEach(box => {
         const randomX = Math.random() * 80; // Random position between 0 and 80% of container width
         const randomY = Math.random() * 80; // Random position between 0 and 80% of container height
         box.style.transform = `translate(${randomX}%, ${randomY}%)`;
      });
   }, []);

   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         className="w-100 mb-24  break-words bg-primaryLight pt-5 md:pt-12 px-6 mx-3 sm:mx-4 lg:mx-24 rounded-2xl relative overflow-hidden"
      >
         <div
            className="flex flex-col gap-5  break-words items-center z-50 "
         >
            <h1
               className="  font-bold text-white break-words max:w-[75rem] text-[2.5rem] sm:text-5xl md:text-7xl tracking-tight md:tracking-[0.019rem] leading-tight md:leading-[5.15rem] text-center"
            >
               Shop Smart. Sell Easy. <br /><div className='flex flex-row space-x-4 justify-center'> <div>Live</div> <div className='text-orange-500'>Better</div>.</div>
            </h1>
            <h2
               title="Seller or Shopper"
               className="font-Kumbh400 text-center  text-[#F5F5F5]  max-w-[57.9375rem] text-sm sm:text-lg  md:leading-[1.6rem]"
            >
               Explore a curated marketplace where vendors meet eager customers,<br></br> creating a tailored shopping experience.
            </h2>
            <div
               className="flex justify-center items-center space-x-2 z-20"
            >
               <button
                  onClick={() => {
                     if (os == "Android" || os == "Windows") { window.open("https://play.google.com/store/apps/details?id=com.jekinraaapp", "_blank"); return }
                     if (os == "iOS" || os == "MacOS") {
                        window.open("https://apps.apple.com/app/jekinraa-the-shopping-app/id6478771670", "_blank"); return
                     } else {
                        window.open("https://play.google.com/store/apps/details?id=com.jekinraaapp", "_blank"); return
                     }
                  }}
                  className='px-5 py-4 bg-white rounded-full flex flex-row space-x-3 cursor-pointer'>
                  <div className='flex flex-row items-center space-x-2'>
                     <img src={android} className="h-4 w-7 pr-2 border-r" alt="google" style={{
                        fill: "black",
                        color: "black",
                        filter: "brightness(1) invert(0)"
                     }} />
                     <img src={apple} className="h-4 w-4" alt="google" style={{
                        fill: "black",
                        color: "black",
                        filter: "brightness(1) invert(0)"
                     }} />
                  </div>
                  <div className='text-primaryLight font-semibold text-sm'>Download App</div>
               </button>
            </div>
            <PinterestGrid items={items} columns={3} />
         </div>
         <div className='hero-container absolute w-full h-full inset-0'>
            <div className="absolute inset-0 ">
               <div className="box box1"></div>
               <div className="box box2"></div>
               <div className="box box3"></div>
               <div className="box box4"></div>
               <div className="box box5"></div>
            </div>
         </div>
      </motion.div >
   )
}

export default memo(HeroUI)